import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import MobileNavBar from "./MobileNavBar";

const NavBar = () => {
  const [showMobileNav, setShowMobileNav] = useState(
    window.matchMedia("(max-width:800px)").matches
  );
  useEffect(() => {
    window.addEventListener("resize", () => {
      setShowMobileNav(window.matchMedia("(max-width:800px)").matches);
    });
  });

  return (
    <React.Fragment>
      {showMobileNav ? (
        <MobileNavBar />
      ) : (
        <nav className="navbar flex items-center justify-between xl:justify-evenly">
          <NavLink to="/">Spectrum Healthcare</NavLink>

          <ul className="flex justify-between items-center navbar-list">
            <NavLink
              to="/about-us"
              className="navbar-list_item mr-[32px]"
              activeclassname="active"
            >
              About Us
            </NavLink>

            <NavLink
              to="/services"
              className="navbar-list_item mr-[32px]"
              activeclassname="active"
            >
              Services
            </NavLink>
            <a href="/contact-us" className="button-outline btn btn-4">
              <span>Contact Us</span>
            </a>
          </ul>
        </nav>
      )}
    </React.Fragment>
  );
};

export default NavBar;
