import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";

const ContactUs = () => {
  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    message: Yup.string()
      .min(10, "Too Short!")
      .max(5000, "Too Long!")
      .required("Required"),
  });

  return (
    <div className="contact">
      <div className="contact_container">
        <div className="flex flex-col-reverse lg:flex-row justify-between contact_container-body">
          <div className="contact-form">
            <div className="contact_container-header">
              <div>
                <h2 className="h2 heading contact_container-header-heading-1">
                  Have a question ? Let’s get in touch with us.
                </h2>
                <p className="text-1 w-full">
                  Fill up the Form and our team will get back to within 24 hrs
                </p>
              </div>
            </div>
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                message: "",
              }}
              validationSchema={SignupSchema}
              onSubmit={(values) => {
                console.log(values, "submitted");
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className="flex flex-col">
                  <div className="mb-[16px]">
                    <input
                      className="text-2 capitalize"
                      type="firstName"
                      name="firstName"
                      placeholder="First Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                    />
                    {errors.firstName && touched.firstName ? (
                      <div className="error-message">{errors.firstName}</div>
                    ) : null}
                  </div>
                  <div className="mb-[16px]">
                    <input
                      className="text-2 capitalize"
                      type="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                    />
                    {errors.lastName && touched.lastName ? (
                      <div className="error-message">{errors.lastName}</div>
                    ) : null}
                  </div>
                  <div className="mb-[16px]">
                    <input
                      className="text-2"
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email ? (
                      <div className="error-message">{errors.email}</div>
                    ) : null}
                  </div>
                  <div className="mb-[16px]">
                    <textarea
                      className="text-2"
                      placeholder="Type Message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      rows="6"
                      name="message"
                    ></textarea>
                    {errors.message && touched.message ? (
                      <div className="error-message">{errors.message}</div>
                    ) : null}
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="button-color submit-btn"
                  >
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          </div>

          <div className="contact-map">
            <div className="contact_container-header flex flex-wrap lg:flex-nowrap">
              <div className="mr-[48px] xl:mr-[76px]">
                <h6 className="h6 heading contact_container-header-heading-2">
                  Location
                </h6>
                <p className="text-1">
                  Spectrum Assisted Living <br />
                  722 Garfield street E <br /> Anoka, MN 55303
                </p>
              </div>
              <div className="contact-address">
                <h6 className="h6 heading contact_container-header-heading-2">
                  Contact Us
                </h6>
                <div className="flex flex-col">
                  <a href="(763) 243-3931" className="text-1">
                    (763) 243-3931
                  </a>
                  <p className="text-1">954-319-7890</p>
                </div>
              </div>
            </div>
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  title="contact-map"
                  width="100%"
                  height="400"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=722%20Garfield%20street%20E%20Anoka,%20MN%2055303&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
