import React from "react";

const Features = () => {
  return (
    <section className="home-features bg-[#2f71b331]">
      <div className="home-features-container">
        <div className="home-features-container_header flex flex-col md:flex-row">
          <h3 className="heading mr-0 md:mr-[40px] mb-[40px] md:mb-0">
            OUR MISSION
          </h3>
          <p className="text-1">
            To provide the Best care to the community that we serve. To give
            compassionate care to our clients.
          </p>
        </div>
        <div className="home-features-container_items">
          <div className="list-items flex flex-col ">
            <h6 className="heading mb-[16px]">How to request services</h6>
            <p className="text-2">
              Clients may be self-referred, or by family members, physicians,
              community organization, hospitals, nursing homes and County, Case
              managers.
            </p>
          </div>
          <div className="list-items flex flex-col ">
            <h6 className="heading mb-[16px]">Who pays for services</h6>
            <p className="text-2">
              Cadi Waivers, Medicaid, Private Insurance Private Payment
            </p>
          </div>
          <div className="list-items flex flex-col ">
            <h6 className="heading mb-[8px]">Our Staff</h6>
            <p className="text-2">
              Are the Best qualified applicants regardless of race, creed,
              color, religion, age, sex, national origin or marital status SHI
              selects and employs applicants who meet the criminal
              Disqualification Act. Open 24hours
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
