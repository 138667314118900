import React from "react";
import Images from "../assets/IMG-20220825-WA0022.jpg";
import Features from "../components/Home/Features";

function AboutUs() {
  return (

    <div className="px-6 sm:px-10 py-20">
      <div className=" text-center my-10">
        <p className="capitalize mb-6 font-semibold text-2xl sm:text-3xl md:text-4xl leading-relaxed tracking-wide sm:tracking-wider gradient-text">
          Welcome To Spectrum Healthcare, Assisted Living Facility{" "}
        </p>
      </div>

      <div className="flex flex-col lg:flex-row justify-evenly items-center py-20">
        <div className="w-full md:w-[40%] mx-auto md:mx-0">
          <img src={Images} className="w-full h-auto" alt="svg" />
        </div>

        <div className="my-10 w-full sm:w-[80%] lg:w-[46%] pb-10">
          <p className="text-base sm:text-xl mt-10 ">
            <span className="font-bold tracking-wider text-[#3a8b02]">
              {" "}
              Remember:-
            </span>{" "}
            <br />
            Just because your needs may change, doesn’t mean your life has to
            change. At Spectrum Healthcare Incorporated, Assisted Living, we
            give you just the help you need so you can do what you want, when
            you want. <br />
            <br />
            We will assist with the activities of daily living which include but
            not limited to housekeeping, transportation an many more.
          </p>
        </div>
      </div>
      <Features />
    </div>
  );
}

export default AboutUs;
