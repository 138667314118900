import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as OpenIcon } from "../assets/nav-icon.svg";
import { ReactComponent as CloseIcon } from "../assets/close-icon.svg";

const MobileNav = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className="mobile-navbar">
      <div className="flex items-center justify-between px-6 py-[20px]">
        <NavLink to="/">
          <p className="mobile-navbar-logo">Spectrum Healthcare</p>
        </NavLink>
        {toggleMenu ? (
          <button
            className="flex justify-center items-center"
            onClick={() => setToggleMenu(false)}
          >
            <CloseIcon />
          </button>
        ) : (
          <button
            className="flex justify-center items-center"
            onClick={() => setToggleMenu(true)}
          >
            <OpenIcon />
          </button>
        )}
      </div>
      {toggleMenu && (
        <div className="mobile-navbar_items">
          <ul className="mobile-navbar_items-list flex flex-col items-center justify-center">
            <NavLink to="/about-us" className="items" activeclassname="active">
              About Us
            </NavLink>

            <NavLink to="/services" className="items" activeclassname="active">
              Services
            </NavLink>
            <a href="/contact-us" className="button-outline btn btn-4">
              <span>Contact Us</span>
            </a>
          </ul>
        </div>
      )}
    </nav>
  );
};

export default MobileNav;
