import React from "react";
import About from "../components/Home/About";
// import Blog from "../components/Home/Blog";
// import Blog2 from "../components/Home/Blog2";
import Features from "../components/Home/Features";
import Header from "../components/Home/Header";
import ContactUs from "./ContactUs";
import HeaderImage from "../assets/header-image.png";

const Home = () => {
  return (
    <div>
      <Header
        header="Healthcare services at your doorstep"
        subheader=" We assist with the activities of daily living which include but not
            limited to housekeeping, transportation an many more."
            image={HeaderImage}
      />
      <About />
      {/* <Blog2 /> */}
      <Features />

      {/* <Blog /> */}
      <ContactUs />
    </div>
  );
};

export default Home;
