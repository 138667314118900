import React from "react";
import AboutImage from "../../assets/IMG_0083.webp";

const About = () => {
  return (
    <section className="home-about">
      <div className="home-about-container flex flex-col lg:flex-row items-center">
        <div className="home-about-container_image pr-10">
          <img src={AboutImage} alt="about" />
        </div>
        <div className="home-about-container_content">
          <h2 className="heading mb-[32px]">Our Services includes</h2>
          <p className="text-1">
            - 24hour Nursing care services <br />- RN on the site to supervise
            care
            <br />- Behavioral Management <br />- Medication Administration{" "}
            <br />- Personal Care Assistance <br />- Preparation of modified
            diet-diabetic and low sodium diet
            <br />- Schedule and accompany clients to appointments <br />-
            Recreation and Social Activities <br />- Transportation <br />-
            House Keeping <br />- Shopping Assistance <br />- Post Hospital
            Medical And Surgical Care <br />- Antibiotics Therapy <br />-
            Tracheotomy Care <br />- Ostomy Care
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
