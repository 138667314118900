import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="footer">
      <div className="footer_container">
        <footer className="justify-around xl:justify-evenly">
          <div className="footer-logo">
            <p className="footer-logo_text">Spectrum Healthcare</p>
          </div>

          <div className="footer-nav footer-nav_1">
            <p className="footer-nav_title text-1">Location</p>
            <p className="text-white">
              We’re located close to Bus line, Shopping Centers and Mall
            </p>
            {/* <ul className="footer-nav_link">
              <Link className="footer-nav_link-items" to="/">
                About Us
              </Link>
              <Link className="footer-nav_link-items" to="/">
                Services
              </Link>
              <Link className="footer-nav_link-items" to="contact-us">
                Contact Us
              </Link>
            </ul> */}
          </div>

          <div className="footer-nav footer-nav">
            <p className="footer-nav_title text-1">Contact</p>
            <ul className="footer-nav_link">
              <li className="footer-nav_link-items">
                (763) 243-393 <br /> 954-319-7890{" "}
              </li>
            </ul>
          </div>
        </footer>
        <div className="footer_container-copyright">
          <div className="text-2 copyright-text">
            © All rights reserved – Spectrum Healthcare
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
