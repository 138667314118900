import React from "react";

const Header = ({ header, subheader, image }) => {
  return (
    <header>
      <div className="header">
        <div className="header-text flex flex-col justify-center">
          <h1 className="heading header-text_heading">{header}</h1>
          <p className="text-1 mt-[12px] w-auto">{subheader}</p>
          {/* <div className="header_buttons">
            <Link
              to="/"
              className="button-color header_buttons-button capitalize"
            >
              See our services
            </Link>
          </div> */}
        </div>
        <div className="header-image flex justify-end items-end">
          <img src={image} alt="headerImage" className="w-full" />
        </div>
      </div>
    </header>
  );
};

export default Header;
